@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Share+Tech+Mono&display=swap');
*{
    margin:0;
    padding: 0;
    background-color: #111;
    font-family: "Share Tech Mono", monospace;
    font-weight: 400;
    font-style: normal;
}

.grid{
    display: flex;
    width: 100vw;
    height: auto;
    background-image: url("../public/grid.svg");
    background-repeat: repeat-y;
    flex-wrap: wrap;
}


.leftHome{
    display: flex;
    width: 40vw;
    height: 100vh;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    background-color: transparent;
}

.barHome{
    width: 30vw;
    margin-bottom: 5vh;
    background-color: transparent;
}

.ach{
    width: 30vw;
    height: 45vh;
    background-color: transparent;
    margin-bottom: 5vh;
}

.achList{
    background-color: transparent;
    margin-left: 2vw;
}

.b1{
    background-color: transparent;
    list-style-image: url("../public/bp.svg");
    border-bottom: solid 1px #0EF8F8;
    margin-bottom: 5vh;
}

.be{
    display: flex;
    align-items: flex-start;
    color:#0EF8F8;
    font-size: 16px;
    margin-bottom: 1vh;
    margin-left: 1vw;
    background-color: transparent;
}


.titleHome{
    width: 30vw;
    background-color: transparent;
}

.rightHome{
    display: flex;
    width: 60vw;
    height: 100vh;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    background-color: transparent;
}

.profilePicContainer{
    display: flex;
    width: 45vw;
    height: 45vw;
    border-radius: 100%;
    border: solid #0EF8F8 2px;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.profilePic{
    width: 30vw;
    border-radius: 100%;
    border: solid #0EF8F8 4vw;
}

.project{
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
}

.p-content{
    display: flex;
    width: 40vw;
    height: 100vh;
    background-color: transparent;
    flex-wrap: wrap;
    align-content: space-evenly;
}
.p-title{
    width: 40vw;
    height: 10vh;
    color:#0EF8F8;
    font-size: 4.7vw;
    background-color: transparent;
    margin-left: 3vw;
}
.p-desc{
    width: 40vw;
    height: 70vh;
    background-color: transparent;
    color:#0EF8F8;
    margin-left: 3vw;
}

.p-details{
    display: flex;
    width: 60vw;
    height: 100vh;
    background-color: transparent;
    flex-wrap: wrap;
}
.p-role{
    display: flex;
    width: 60vw;
    height: 40vh;
    justify-content: center;
    background-color: transparent;
    color:#0EF8F8;
    font-size: 2vw;
    align-items: center;
    line-height: 2;
    padding-top: 2vh;
}
.p-img-container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 60vw;
    height: 58vh;
    background-color: transparent;
}
.p-img{
    width: 55vw;
    background-color: transparent;
}
